import styled, { css } from 'styled-components'

import { ButtonPrimary } from 'components/atoms/Button'

import media from 'styles/media'

export const CookiesWrapper = styled.aside`
  position: fixed;
  bottom: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  width: 600px;
  border: 2px solid ${({ theme }) => theme.colors.primary50};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  opacity: 1;
  max-width: calc(100% - 32px);
  z-index: 100;
  transition: 0.3s ease;

  ${media.md.min} {
    left: 32px;
    bottom: 32px;
    max-width: calc(100% - 64px);
  }
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

export const CookiesButton = styled.button`
  ${ButtonPrimary}
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 6px;
`

export const CookiesText = styled.p`
  margin: 0;
  padding: 0 16px 0 0;
  font-size: 16px;
  line-height: 22px;
  width: calc(100% - 60px);
  ${media.lg.min} {
    font-size: 18px;
    line-height: 24px;
  }
`
