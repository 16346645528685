import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import HeaderStatic from 'components/organisms/Header/HeaderStatic'
import HeaderSticky from 'components/organisms/Header/HeaderSticky'
import Sidenav from 'components/organisms/Sidenav'
import Footer from 'components/organisms/Footer'

import media from 'styles/media'

import useBreakpoint from 'hooks/useBreakpoint'
import Cookies from 'components/organisms/Cookies'

const Wrapper = styled.div`
  position: relative;
  padding: 108px 0 0 0;
  ${media.lg.min} {
    padding: 86px 0 0 0;
  }
`
type Props = {
  children: React.ReactNode
}

const LayoutBasic: React.FC<Props> = ({ children }) => {
  const { lg } = useBreakpoint()

  const [sidenavVisible, setSidenavVisible] = useState(false)

  const openSidenav = () => {
    setSidenavVisible(true)
  }

  const closeSidenav = () => {
    setSidenavVisible(false)
  }

  return (
    <Wrapper>
      <Helmet>
        <script type="text/javascript">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MP6F9C3');`}</script>
        <script type="text/javascript">{`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
if (typeof window !== "undefined") { if (window.fbq != null) {fbq('init', '1081255849501037');
fbq('track', 'PageView');}}`}</script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11138408517"
        />

        <script type="text/javascript">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-11138408517');`}</script>

        <meta
          name="facebook-domain-verification"
          content="okwnac5vxj4qizrtb0xk2tdjrk3o12"
        />
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MP6F9C3"
          title="GTM iframe"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1081255849501037&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
      <HeaderStatic openSidenav={openSidenav} />
      <HeaderSticky openSidenav={openSidenav} />
      {!lg && <Sidenav closeSidenav={closeSidenav} visible={sidenavVisible} />}
      <main>{children}</main>
      <Cookies />
      <Footer />
    </Wrapper>
  )
}

export default LayoutBasic
