import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type SeoProps = {
  title: string
  description?: string
  lang?: string
  ogTitle?: string | null
  ogDescription?: string | null
  ogImage?: string | null
  twitterTitle?: string | null
  twitterDescription?: string | null
  twitterImage?: string | null
  robots?: string
}

type SiteMetadata = {
  site: { siteMetadata: { title: string; description: string; author: string } }
}

const Seo: React.FC<SeoProps> = ({
  title,
  description,
  lang,
  ogTitle,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  robots = 'index, follow',
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteMetadata>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || siteMetadata.description

  const defaultMeta = [
    {
      property: `robots`,
      content: robots,
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: ogTitle || title,
    },
    {
      property: `og:description`,
      content: ogDescription || metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: twitterTitle || title,
    },
    {
      name: `twitter:description`,
      content: twitterDescription || metaDescription,
    },
  ]

  if (ogImage) {
    defaultMeta.push({ name: `og:image`, content: ogImage })
  }

  if (twitterImage) {
    defaultMeta.push({ name: `twitter:image`, content: twitterImage })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="%s"
      meta={defaultMeta}
    />
  )
}

export default Seo
