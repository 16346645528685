import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  FooterWrapper,
  FooterInner,
  FooterMain,
  FooterBrandWrapper,
  FooterBrand,
  FooterContent,
  FooterText,
  FooterSub,
  FooterCopyBrandWrapper,
  FooterCopyBrand,
} from 'components/organisms/Footer/Footer.style'
import Container from 'components/atoms/Container'

import { phoneClickTracker } from 'utils/analytics'

import logo from 'assets/icons/logo.svg'
import logoPekabex from 'assets/icons/pekabex.svg'

const Footer: React.FC = () => {
  const query = useStaticQuery(graphql`
    query Footer {
      allWp {
        edges {
          node {
            themeSettings {
              AcfOptionsTheme {
                globalOfficePhone
                globalOfficeName
                globalOfficeEmail
                globalOfficeAddress
                globalOfficeAddress2
              }
            }
          }
        }
      }
    }
  `)

  const DATA = query?.allWp?.edges[0].node.themeSettings.AcfOptionsTheme

  if (!DATA) throw new Error("CMS data didn't load properly")

  return (
    <FooterWrapper>
      <Container>
        <FooterInner>
          <FooterMain>
            <FooterBrandWrapper to="/">
              <FooterBrand src={logo} alt="Zatoka Wrzosowo logo" />
            </FooterBrandWrapper>
            <FooterContent>
              <FooterText
                dangerouslySetInnerHTML={{
                  __html: DATA.globalOfficeName
                    .replace(/{/g, '<strong>')
                    .replace(/}/g, '</strong>'),
                }}
              />
              <FooterText
                dangerouslySetInnerHTML={{
                  __html: DATA.globalOfficeAddress
                    .replace(/{/g, '<strong>')
                    .replace(/}/g, '</strong>'),
                }}
              />
              <FooterText
                dangerouslySetInnerHTML={{
                  __html: DATA.globalOfficeAddress2
                    .replace(/{/g, '<strong>')
                    .replace(/}/g, '</strong>'),
                }}
              />
              <FooterText>
                <p>
                  <a
                    onClick={phoneClickTracker}
                    href={`tel:${DATA.globalOfficePhone!}`}
                  >
                    Tel. {DATA.globalOfficePhone!}
                  </a>
                  <br />
                  <a href={`mailto:${DATA.globalOfficeEmail!}`}>
                    {DATA.globalOfficeEmail!}
                  </a>
                </p>
              </FooterText>
            </FooterContent>
          </FooterMain>
          <FooterSub>
            <FooterCopyBrandWrapper
              href="https://pekabexdevelopment.pl/"
              target="_blank"
              rel="noindex nofollow"
            >
              <FooterCopyBrand
                src={logoPekabex}
                alt="Pekabex Development logo"
              />
            </FooterCopyBrandWrapper>
          </FooterSub>
        </FooterInner>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
