export const NAV_LINKS = [
  {
    title: 'O inwestycji',
    path: '#o-inwestycji',
  },
  {
    title: 'Lokalizacja',
    path: '#lokalizacja',
  },
  {
    title: 'Pekabex Development',
    path: '#pekabex-development',
  },
  {
    title: 'Kontakt',
    path: '#kontakt-dolny',
  },
]
