import styled from 'styled-components'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

export const BoxWrapper = styled.section`
  padding: 112px 0;
  background-color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    padding: 64px 0;
  }
`

export const BoxInner = styled.div`
  display: flex;
  align-items: stretch;
  max-width: 1200px;
  margin: 0 auto;
  ${media.lg.max} {
    align-items: center;
    flex-direction: column;
  }
`

export const BoxGallery = styled(Slider)`
  padding: 0 64px 0 0;
  width: 50%;
  ${media.lg.max} {
    padding: 0;
    width: 100%;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }
`

export const BoxGallerySlide = styled.div`
  position: relative;
  height: 100%;
`

export const BoxImage = styled(LazyImage)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  ${media.lg.max} {
    position: relative;
    height: auto;
  }
`

export const BoxContent = styled.div`
  width: 50%;
  ${media.lg.max} {
    width: 100%;
    margin: 32px 0 0 0;
  }
`

export const BoxTitle = styled.h2`
  position: relative;
  margin: 0 0 40px 0;
  padding: 0 0 32px 0;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 140px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary50};
  }
  ${media.lg.max} {
    margin: 0 0 32px 0;
    padding: 0 0 16px 0;
    font-size: 32px;
    line-height: 40px;
    &:before {
      width: 100px;
    }
  }
`

export const BoxText = styled.div`
  p {
    margin: 0 0 32px 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};

    strong {
      font-weight: 700;
    }
    &:last-child {
      margin: 0;
    }

    ${media.lg.max} {
      margin: 0 0 16px 0;
      font-size: 17px;
      line-height: 25px;
    }
  }
`
