import React from 'react'

import {
  Wrapper,
  MapImage,
  Inner,
  Control,
} from 'components/organisms/Map/MapSimple.style'
import Container from 'components/atoms/Container'

import useBreakpoint from 'hooks/useBreakpoint'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  id?: string
  imageMobile: IGatsbyImageData
  imageDesktop: IGatsbyImageData
  buttonUrl: string
}

const MapSimple: React.FC<Props> = ({
  id,
  imageMobile,
  imageDesktop,
  buttonUrl,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper id={id}>
      <MapImage
        objectFit="contain"
        src={lg ? imageDesktop : imageMobile}
        alt=""
      />
      <Container>
        <Inner>
          <Control href={buttonUrl} target="_blank">
            Przejdź do mapy Google
          </Control>
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default MapSimple
