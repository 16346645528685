import styled, { css } from 'styled-components'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ButtonPrimary } from 'components/atoms/Button'
import { LazyImage } from 'components/atoms/Image'

import backgroundDecorSecondary from 'assets/icons/decor-flower-secondary-v2.svg'

import media from 'styles/media'

export const BannerWrapper = styled.section`
  position: relative;
`

export const BannerInner = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    align-items: stretch;
    min-height: 550px;
  }
`

export const BannerContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  ${media.lg.min} {
    padding: 88px 32px 32px 0;
    min-width: 735px;
    width: 50%;
    overflow: hidden;
  }
  ${media.xxl.min} {
    padding: 120px 64px 64px 0;
    min-width: 765px;
  }
`

export const BannerContentInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 32px;

  ${media.xxl.min} {
    padding: 64px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    bottom: -275px;
    right: -175px;
    width: 500px;
    height: 100%;
    background-image: url(${backgroundDecorSecondary});
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;

    ${media.lg.max} {
      display: none;
    }
  }
`

const bannerBorderStyles = css`
  &::before,
  &:after {
    content: '';
    position: absolute;
  }
`

export const BannerBorder1 = styled.span<{ playAnimation: boolean }>`
  ${bannerBorderStyles}

  &::before,:after {
    top: 0;
    left: 0;
    background-color: ${({ playAnimation, theme }) =>
      playAnimation ? theme.colors.primary50 : 'transparent'};
  }

  &:before {
    height: 2px;
    width: ${({ playAnimation }) => (playAnimation ? '100%' : '2px')};
    transition: width 1s ease-in-out 400ms, background-color 100ms ease-in-out;
  }

  &:after {
    width: 2px;
    height: ${({ playAnimation }) => (playAnimation ? '100%' : '2px')};
    transition: height 1s ease-in-out 400ms, background-color 100ms ease-in-out;
  }
`

export const BannerBorder2 = styled.span<{ playAnimation: boolean }>`
  ${bannerBorderStyles}

  &::before,:after {
    background-color: ${({ playAnimation, theme }) =>
      playAnimation ? theme.colors.primary50 : 'transparent'};
  }

  &:before {
    right: 0;
    top: 0;
    height: ${({ playAnimation }) => (playAnimation ? '100%' : '2px')};
    width: 2px;
    transition: height 1s ease-in-out 1400ms,
      background-color 100ms ease-in-out 1400ms;
  }

  &:after {
    left: 0;
    bottom: 0;
    width: ${({ playAnimation }) => (playAnimation ? '100%' : '2px')};
    height: 2px;
    transition: width 1s ease-in-out 1400ms,
      background-color 100ms ease-in-out 1400ms;
  }
`

export const BannerTitle = styled.h1`
  position: relative;
  margin: 0 0 32px 0;
  padding: 0 0 32px 0;
  font-size: 42px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.black};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 140px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  sup {
    font-size: 36px;
    line-height: 36px;
  }

  span {
    font-size: 40px;
    line-height: 50px;
  }

  ${media.lg.max} {
    font-size: 34px;
    line-height: 42px;

    sup {
      font-size: 17px;
      line-height: 30px;
    }

    span {
      font-size: 19px;
      line-height: 30px;
    }
  }
`

export const BannerSubtitle = styled.h2`
  font-size: 26px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.black};

  span {
    display: block;
    font-size: 64px;
    line-height: 72px;
    width: 100%;
    strong {
      color: ${({ theme }) => theme.colors.primary50};
      font-weight: 700;
    }
  }

  ${media.lg.max} {
    span {
      font-size: 48px;
      line-height: 56px;
    }
  }
`

export const BannerGallery = styled.div`
  position: relative;
  width: 100%;
  ${media.lg.min} {
    width: 55vw;
  }
`

export const BannerGalleryControls = styled.div`
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  ${media.sm.max} {
    left: 16px;
  }
`

export const BannerGalleryControl = styled.button<{ reversed?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 0;
  border-radius: 48px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.3);
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary50};
    svg path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &:nth-child(2) {
    margin: 12px 0 0 0;
  }

  svg {
    height: 14px;
    width: 14px;
    object-fit: contain;
    path {
      fill: ${({ theme }) => theme.colors.primary50};
      transition: fill 300ms ease-in-out;
    }
  }

  ${({ reversed }) =>
    reversed &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `}
`

export const BannerGallerySlider = styled(Slider)`
  margin: 24px 0 0 0;
  height: 100%;
  ${media.lg.min} {
    margin: 0;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
    }
  }
`

export const BannerGallerySlide = styled.div`
  position: relative;
  height: 100%;
`

export const BannerGalleryImg = styled(LazyImage)`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${media.lg.max} {
    position: relative;
    height: calc(100vw - 32px);
    max-height: 550px;
  }
`

export const BannerCtaWrapper = styled.div`
  position: relative;
  margin: 48px 0 0 0;
`

export const BannerCtaTitle = styled.p`
  margin: 0 0 12px 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
`

export const BannerCta = styled.button`
  ${ButtonPrimary}
`

export const BannerDisclaimer = styled.span`
  position: absolute;
  left: 32px;
  bottom: 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};

  ${media.xxl.min} {
    left: 64px;
    bottom: 16px;
  }
`
