import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  HeaderWrapper,
  HeaderInner,
  HeaderBrand,
  HeaderContent,
  Navigation,
  NavigationItem,
  NavigationLink,
  HeaderContact,
  HeaderContactText,
  HeaderToggler,
  HeaderTogglerIcon,
} from 'components/organisms/Header/HeaderSticky.style'
import Container from 'components/atoms/Container/'

import useScrollPosition from 'hooks/useScroll'
import useScrollHide from 'hooks/useScrollHide'
import scrollToSection from 'utils/scrollToSection'

import generateKey from 'utils/generateKey'
import { phoneClickTracker, contactClickTracker } from 'utils/analytics'

import { NAV_LINKS } from 'constants/navLinks'

import logo from 'assets/icons/logo.svg'
import iconBurger from 'assets/icons/burger.svg'

type Props = {
  openSidenav: () => void
}

const HeaderSticky: React.FC<Props> = ({ openSidenav }) => {
  const query = useStaticQuery(graphql`
    query HeaderSticky {
      allWp {
        edges {
          node {
            themeSettings {
              AcfOptionsTheme {
                globalContactPhone
              }
            }
          }
        }
      }
    }
  `)

  const DATA = query?.allWp?.edges[0].node.themeSettings.AcfOptionsTheme

  if (!DATA) throw new Error("CMS data didn't load properly")

  const position = useScrollPosition(0)
  const isHidden = useScrollHide(0, 0)

  return (
    <HeaderWrapper active={!isHidden && position.y > 220}>
      <Container>
        <HeaderInner>
          <HeaderBrand to="/">
            <img src={logo} alt="" />
          </HeaderBrand>
          {NAV_LINKS && (
            <Navigation>
              {NAV_LINKS.map((item, index) => (
                <NavigationItem key={generateKey(index)}>
                  <NavigationLink
                    onClick={() => {
                      scrollToSection(item.path, 'smooth')

                      if (item.path.includes('kontakt')) {
                        contactClickTracker()
                      }
                    }}
                    type="button"
                  >
                    {item.title}
                  </NavigationLink>
                </NavigationItem>
              ))}
            </Navigation>
          )}
          <HeaderContent>
            <HeaderContact
              onClick={phoneClickTracker}
              href={`tel:${DATA.globalContactPhone}`}
            >
              <HeaderContactText>{DATA.globalContactPhone}</HeaderContactText>
            </HeaderContact>
            <HeaderToggler onClick={openSidenav}>
              <HeaderTogglerIcon src={iconBurger} size={28} />
            </HeaderToggler>
          </HeaderContent>
        </HeaderInner>
      </Container>
    </HeaderWrapper>
  )
}

export default HeaderSticky
