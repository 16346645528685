import styled, { css } from 'styled-components'

import { Props } from 'components/atoms/Form/Checkbox.types'

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 0.5rem;
  font-size: 12px;
  line-height: 1.3;
  padding: 3px 0 0 30px;
  cursor: pointer;
`

export const LabelText = styled.p<{
  expanded: boolean
  styleType: Props['styleType']
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.black};
  margin: 0;

  a {
    text-decoration: underline;
    color: ${({ styleType, theme }) =>
      styleType === 'primary' ? theme.colors.white : theme.colors.black};
  }
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
      word-break: normal;
    `}
`

export const LabelBtn = styled.button<{ styleType: Props['styleType'] }>`
  background: transparent;
  border: none;
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.black};
  cursor: pointer;
  align-self: flex-start;
  padding: 0 0 0 10px;
  margin-left: auto;
`

export const Checkmark = styled.span<{
  error: boolean
  styleType: Props['styleType']
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px solid;
  transition: 0.2s;
  border-radius: 0;

  ${({ styleType, theme, error }) =>
    `border-color: ${
      /* eslint-disable no-nested-ternary */
      error
        ? theme.colors.alert50
        : styleType === 'primary'
        ? theme.colors.white
        : theme.colors.black
    };
    `}
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    background-color: ${({ styleType, theme }) =>
      styleType === 'primary' ? theme.colors.white : theme.colors.primary50};
  }
`

export const Checkbox = styled.input<{ styleType: Props['styleType'] }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark} {
    background: transparent;
    border-color: ${({ styleType, theme }) =>
      styleType === 'primary' ? theme.colors.white : theme.colors.primary50};
    &:after {
      opacity: 1;
    }
  }
`
