import React from 'react'
import { Image } from 'components/atoms/Image'

type IconProps = {
  src: string
  size?: number
  className?: string
}

export const Icon: React.FC<IconProps> = ({ src, size = 16, className }) => (
  <Image
    src={src}
    alt=""
    width={size}
    height={size}
    className={className}
    objectFit="contain"
    objectPosition="center"
  />
)
