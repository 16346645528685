import React from 'react'

import {
  ContactWrapper,
  ContactInner,
  ContactHeader,
  ContactTitle,
} from 'components/organisms/Contact/Contact.style'
import ContactForm from 'components/modules/ContactForm'
import Container from 'components/atoms/Container'

type Props = {
  id?: string
  title?: string
  styleType?: 'primary'
}

const Contact: React.FC<Props> = ({ id, styleType, title }) => {
  return (
    <ContactWrapper styleType={styleType} id={id}>
      <Container>
        <ContactInner>
          {title && (
            <ContactHeader>
              <ContactTitle
                styleType={styleType}
                dangerouslySetInnerHTML={{
                  __html: title
                    .replace(/{/g, '<strong>')
                    .replace(/}/g, '</strong>'),
                }}
              />
            </ContactHeader>
          )}
          <ContactForm id={id} styleType={styleType} />
        </ContactInner>
      </Container>
    </ContactWrapper>
  )
}

export default Contact
