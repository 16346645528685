import React, { useState, useEffect } from 'react'
import Cookie from 'js-cookie'

import {
  CookiesWrapper,
  CookiesText,
  CookiesButton,
} from 'components/organisms/Cookies/Cookies.style'

const COOKIE_KEY = 'zatokawrzosowo-cookies-consent'

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set(COOKIE_KEY, 'true')
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)

    localStorage.setItem(COOKIE_KEY, 'true')
  }

  useEffect(() => {
    const value = localStorage.getItem(COOKIE_KEY)
    if (value) {
      setVisible(false)
      setTimeout(() => setRemoveAlert(true), 500)
    } else {
      setVisible(true)
    }
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <CookiesText>
            Strona korzysta z plików cookies w celu realizacji usług. Możesz
            określić warunki przechowywania lub dostępu do plików cookies w
            Twojej przeglądarce
          </CookiesText>
          <CookiesButton onClick={acceptCookies}>OK</CookiesButton>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
