import styled from 'styled-components'
import media from 'styles/media'

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: calc(1640px + 32px);
  ${media.sm.min} {
    padding-left: 32px;
    padding-right: 32px;
    max-width: calc(1640px + 64px);
  }
  ${media.xxl.min} {
    padding-left: 64px;
    padding-right: 64px;
    max-width: calc(1640px + 128px);
  }
`
