export const phoneClickTracker = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'Click',
        label: 'Numer telefonu',
      })
    }

    // @ts-ignore
    if (window.fbq != null) {
      // @ts-ignore
      fbq('track', 'LeadPhone', {
        event: 'Click',
        label: 'Numer telefonu',
      })
    }
  }
}

export const contactClickTracker = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'Click',
        label: 'LeadSectionContact',
      })
    }

    // @ts-ignore
    if (window.fbq != null) {
      // @ts-ignore
      fbq('track', 'LeadSectionContact', {
        event: 'Click',
        label: 'Kontakt',
      })
    }
  }
}
