import styled from 'styled-components'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Container from 'components/atoms/Container'
import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

export const OverlayedWrapper = styled.section`
  position: relative;
`

export const OverlayedSlider = styled(Slider)`
  position: relative;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }
`

export const OverlayedSliderControls = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  & ${Container} {
    height: 100%;
  }
`

export const OverlayedSliderControlsInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const OverlayedSliderDots = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0;
  width: 64px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 300px;
  overflow: hidden;
  transform: translateY(-50%);
  pointer-events: auto !important;
  ${media.lg.max} {
    display: none !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }
`

export const OverlayedSliderDot = styled.button<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px 0;
  height: 56px;
  width: 64px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    display: block;
    border: 2px solid ${({ theme }) => theme.colors.white};
    background-color: transparent;
    transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out;
    ${({ active, theme }) =>
      active &&
      `
    background-color: ${theme.colors.white};
  `}
  }
  &:last-child {
    margin: 0;
  }
  &:hover {
    &:before {
      opacity: 0.72;
    }
  }
`

export const OverlayedSliderArrows = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100vw;
  transform: translateX(-50%);
  ${media.lg.min} {
    max-width: 500px;
  }
`

export const OverlayedSliderArrow = styled.button<{ modificator?: string }>`
  position: relative;
  margin: 0;
  padding: 24px 32px;
  width: 50%;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary50};
  border: 0;
  border-radius: 0;
  cursor: pointer;
  pointer-events: auto !important;
  z-index: 9;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.72;
  }

  /* &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
  } */

  ${({ modificator, theme }) =>
    modificator === 'right' &&
    `
    background-color: ${theme.colors.primary100};
  `}
`

export const OverlayedSlide = styled.article`
  position: relative;
  height: 100%;
`

export const OverlayedInner = styled.div`
  position: relative;
  padding: 64px 0 128px 0;
  width: 100%;
  z-index: 2;

  ${media.lg.min} {
    padding: 72px 0 180px 0;
  }
`

export const OverlayedBackground = styled(LazyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      75deg,
      ${({ theme }) => theme.colors.primary50},
      transparent
    );
    z-index: 1;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const OverlayedContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`

export const OverlayedTitle = styled.h2`
  position: relative;
  margin: 0 0 64px 0;
  padding: 0 0 48px 0;
  font-size: 48px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.white};
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 140px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${media.lg.max} {
    margin: 0 0 32px 0;
    padding: 0 0 16px 0;
    font-size: 32px;
    line-height: 40px;
    &:before {
      width: 100px;
    }
  }
`

export const OverlayedText = styled.div`
  p {
    margin: 0 0 32px 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};

    strong {
      font-weight: 700;
    }
    &:last-child {
      margin: 0;
    }

    ${media.lg.max} {
      margin: 0 0 16px 0;
      font-size: 17px;
      line-height: 25px;
    }
  }
`

export const OverlayedPictograms = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  margin: 80px 0 0 0;
  max-width: 1172px;
  padding: 0 72px 0 0;
  ${media.lg.max} {
    margin: 64px 0 0 0;
    max-width: 100%;
    grid-row-gap: 32px;
    padding: 0;
  }

  ${media.md.max} {
    margin: 48px 0 0 0;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 24px;
  }
`

export const OverlayedPictogram = styled.div`
  display: flex;
  align-items: center;
`

export const OverlayedPictogramIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    width: 80px;
    height: 80px;
  }
`

export const OverlayedPictogramContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 32px;
  width: calc(100% - 100px);
`

export const OverlayedPictogramTitle = styled.h3`
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    font-size: 22px;
    line-height: 30px;
  }
`

export const OverlayedPictogramText = styled.p`
  margin: 4px 0 0 0;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
  ${media.lg.max} {
    font-size: 15px;
    line-height: 23px;
  }
`
