import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  HeaderWrapper,
  HeaderBar,
  HeaderBarInner,
  HeaderContact,
  HeaderContactWrapper,
  HeaderContent,
  HeaderContentInner,
  HeaderContentMobile,
  HeaderBrand,
  Navigation,
  NavigationItem,
  NavigationLink,
  HeaderToggler,
  HeaderTogglerIcon,
} from 'components/organisms/Header/HeaderStatic.style'
import Container from 'components/atoms/Container/'

import logo from 'assets/icons/logo.svg'
import iconBurger from 'assets/icons/burger.svg'

import { NAV_LINKS } from 'constants/navLinks'

import generateKey from 'utils/generateKey'
import scrollToSection from 'utils/scrollToSection'
import { phoneClickTracker, contactClickTracker } from 'utils/analytics'

type Props = {
  openSidenav: () => void
}

const HeaderStatic: React.FC<Props> = ({ openSidenav }) => {
  const query = useStaticQuery(graphql`
    query HeaderStatic {
      allWp {
        edges {
          node {
            themeSettings {
              AcfOptionsTheme {
                globalContactPhone
              }
            }
          }
        }
      }
    }
  `)

  const DATA = query?.allWp?.edges[0].node.themeSettings.AcfOptionsTheme

  if (!DATA) throw new Error("CMS data didn't load properly")

  return (
    <HeaderWrapper>
      <HeaderBar>
        <Container>
          <HeaderBarInner>
            <HeaderContactWrapper>
              <HeaderContact
                onClick={phoneClickTracker}
                href={`tel:${DATA.globalContactPhone}`}
              >
                {DATA.globalContactPhone}
              </HeaderContact>
            </HeaderContactWrapper>
          </HeaderBarInner>
        </Container>
      </HeaderBar>
      <HeaderContent>
        <Container>
          <HeaderContentInner>
            <HeaderBrand to="/">
              <img src={logo} alt="" />
            </HeaderBrand>
            {NAV_LINKS && (
              <Navigation>
                {NAV_LINKS.map((item, index) => (
                  <NavigationItem key={generateKey(index)}>
                    <NavigationLink
                      onClick={() => {
                        scrollToSection(item.path, 'smooth')

                        if (item.path.includes('kontakt')) {
                          contactClickTracker()
                        }
                      }}
                      type="button"
                    >
                      {item.title}
                    </NavigationLink>
                  </NavigationItem>
                ))}
              </Navigation>
            )}
            <HeaderContentMobile>
              <HeaderContact
                onClick={phoneClickTracker}
                href={`tel:${DATA.globalContactPhone}`}
              />
              <HeaderToggler onClick={openSidenav}>
                <HeaderTogglerIcon src={iconBurger} size={28} />
              </HeaderToggler>
            </HeaderContentMobile>
          </HeaderContentInner>
        </Container>
      </HeaderContent>
    </HeaderWrapper>
  )
}

export default HeaderStatic
