import React, { useState, useEffect, useRef } from 'react'

import {
  BannerWrapper,
  BannerInner,
  BannerContent,
  BannerContentInner,
  BannerBorder1,
  BannerBorder2,
  BannerTitle,
  BannerSubtitle,
  BannerCtaWrapper,
  BannerCtaTitle,
  BannerCta,
  BannerGallery,
  BannerGalleryControls,
  BannerGalleryControl,
  BannerGallerySlider,
  BannerGallerySlide,
  BannerGalleryImg,
} from 'components/organisms/Banner/BannerMain.style'
import Container from 'components/atoms/Container'

import scrollToSection from 'utils/scrollToSection'
import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  title: string
  subtitle?: string
  controlsTitle?: string
  buttonTitle: string
  buttonUrl: string
  gallery: Array<{
    src: IGatsbyImageData
    alt: string
  }>
}

const BannerMain: React.FC<Props> = ({
  title,
  subtitle,
  controlsTitle,
  buttonTitle,
  buttonUrl,
  gallery,
}) => {
  const [playAnimation, setPlayAnimation] = useState(false)

  const gallerySlider = useRef<any>(null)

  useEffect(() => {
    setPlayAnimation(true)
  }, [])

  return (
    <BannerWrapper>
      <Container>
        <BannerInner>
          <BannerContent>
            <BannerContentInner>
              <BannerBorder1 playAnimation={playAnimation} />
              <BannerBorder2 playAnimation={playAnimation} />
              <BannerTitle
                dangerouslySetInnerHTML={{
                  __html: title
                    .replace(/{/g, '<strong>')
                    .replace(/}/g, '</strong>'),
                }}
              />
              {subtitle && (
                <BannerSubtitle
                  dangerouslySetInnerHTML={{
                    __html: subtitle
                      .replace(/{/g, '<strong>')
                      .replace(/}/g, '</strong>'),
                  }}
                />
              )}
              {buttonTitle && (
                <BannerCtaWrapper>
                  {controlsTitle && (
                    <BannerCtaTitle
                      dangerouslySetInnerHTML={{
                        __html: controlsTitle,
                      }}
                    />
                  )}
                  <BannerCta
                    onClick={() => scrollToSection(buttonUrl, 'smooth')}
                    type="button"
                  >
                    {buttonTitle}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.315"
                      height="12.838"
                      viewBox="0 0 14.315 12.838"
                    >
                      <g id="Group_586" data-name="Group 586">
                        <path
                          id="Path_25819"
                          data-name="Path 25819"
                          d="M14.166,6.035a.538.538,0,0,1-.2.873c-.6.273-1.21.54-1.815.81L.824,12.764A.544.544,0,0,1,.1,12.622a.533.533,0,0,1,.013-.614Q.771,10.952,1.432,9.9q1.05-1.678,2.1-3.356a.2.2,0,0,0,0-.248Q1.835,3.6.14.9A.606.606,0,0,1,.028.348.519.519,0,0,1,.764.056q1.125.5,2.248,1L13.865,5.879c.1.046.2.1.3.156"
                          transform="translate(0.001 0)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </BannerCta>
                </BannerCtaWrapper>
              )}
            </BannerContentInner>
          </BannerContent>
          {gallery && (
            <BannerGallery>
              <BannerGalleryControls>
                <BannerGalleryControl
                  reversed
                  onClick={() => gallerySlider.current.slickPrev()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.315"
                    height="12.838"
                    viewBox="0 0 14.315 12.838"
                  >
                    <g id="Group_586" data-name="Group 586">
                      <path
                        id="Path_25819"
                        data-name="Path 25819"
                        d="M14.166,6.035a.538.538,0,0,1-.2.873c-.6.273-1.21.54-1.815.81L.824,12.764A.544.544,0,0,1,.1,12.622a.533.533,0,0,1,.013-.614Q.771,10.952,1.432,9.9q1.05-1.678,2.1-3.356a.2.2,0,0,0,0-.248Q1.835,3.6.14.9A.606.606,0,0,1,.028.348.519.519,0,0,1,.764.056q1.125.5,2.248,1L13.865,5.879c.1.046.2.1.3.156"
                        transform="translate(0.001 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </BannerGalleryControl>
                <BannerGalleryControl
                  onClick={() => gallerySlider.current.slickNext()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.315"
                    height="12.838"
                    viewBox="0 0 14.315 12.838"
                  >
                    <g id="Group_586" data-name="Group 586">
                      <path
                        id="Path_25819"
                        data-name="Path 25819"
                        d="M14.166,6.035a.538.538,0,0,1-.2.873c-.6.273-1.21.54-1.815.81L.824,12.764A.544.544,0,0,1,.1,12.622a.533.533,0,0,1,.013-.614Q.771,10.952,1.432,9.9q1.05-1.678,2.1-3.356a.2.2,0,0,0,0-.248Q1.835,3.6.14.9A.606.606,0,0,1,.028.348.519.519,0,0,1,.764.056q1.125.5,2.248,1L13.865,5.879c.1.046.2.1.3.156"
                        transform="translate(0.001 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </BannerGalleryControl>
              </BannerGalleryControls>
              <BannerGallerySlider
                ref={gallerySlider}
                dots={false}
                arrows={false}
                infinite
                slidesToShow={1}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={2000}
                fade
              >
                {gallery.map((image, imageIndex) => (
                  <BannerGallerySlide key={generateKey(imageIndex)}>
                    <BannerGalleryImg src={image.src} alt={image.alt} />
                  </BannerGallerySlide>
                ))}
              </BannerGallerySlider>
            </BannerGallery>
          )}
        </BannerInner>
      </Container>
    </BannerWrapper>
  )
}

export default BannerMain
