import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import BannerMain from 'components/organisms/Banner/BannerMain'
import Contact from 'components/organisms/Contact'
import OverlayedCarousel from 'components/organisms/Overlayed/OverlayedCarousel'
import Box from 'components/organisms/Box'
import About from 'components/organisms/About'
import MapSimple from 'components/organisms/Map/MapSimple'
import Pictograms from 'components/organisms/Pictograms'

const PageIndex: React.FC<PageProps<Queries.PageIndexQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.PageHome

  if (!PAGE) throw new Error("CMS data didn't load properly")

  const dataBannerGallery = PAGE?.homeBannerGallery?.map((item) => ({
    src: item?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: item?.altText!,
  }))

  const dataOverlayedCarousel = PAGE?.homeBenefitsList?.map((slide) => ({
    title: slide?.homeBenefitsListTitle!,
    text: slide?.homeBenefitsListText!,
    background: {
      desktop:
        slide?.homeBenefitsListPhoto?.localFile?.childImageSharp
          ?.gatsbyImageData!,
      mobile:
        slide?.homeBenefitsListPhotoMobile?.localFile?.childImageSharp
          ?.gatsbyImageData!,
    },
    pictograms: slide!.homeBenefitsListPictograms?.map((pictogram) => ({
      icon: pictogram?.homeBenefitsListPictogramsIcon?.localFile?.publicURL!,
      title: pictogram?.homeBenefitsListPictogramsTitle!,
      text: pictogram?.homeBenefitsListPictogramsText!,
    })),
  }))

  const dataBoxGallery = PAGE?.homeAboutGallery!.map((item) => ({
    image:
      item?.homeAboutGalleryImg?.localFile?.childImageSharp?.gatsbyImageData!,
  }))

  const dataAboutCards = PAGE?.homeAbout2Cards!.map((item) => ({
    title: item?.homeAbout2CardsTitle!,
    text: item?.homeAbout2CardsText!,
  }))

  // const dataAboutStats = PAGE?.homeAbout2Pekabex!.map((item) => ({
  //   title: item?.homeAbout2PekabexNumber!,
  //   text: item?.homeAbout2PekabexText!,
  // }))

  const dataPictogramsList = PAGE?.homeForInvestorsBenefits!.map((item) => ({
    icon: item?.homeForInvestorsBenefitsFirstRowIcons?.localFile?.publicURL!!,
    text: item?.homeForInvestorsBenefitsFirstRowText!,
  }))

  return (
    <LayoutBasic>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
        twitterTitle={data?.wpPage?.seo?.twitterTitle}
        twitterDescription={data?.wpPage?.seo?.twitterDescription}
        twitterImage={data?.wpPage?.seo?.twitterImage?.sourceUrl}
      />
      <BannerMain
        title={PAGE?.homeBannerTitle!}
        subtitle={PAGE?.homeBannerSubtitle!}
        controlsTitle={PAGE?.homeBannerSubtitlePresaleStart!}
        buttonTitle={PAGE?.homeBannerBtn?.title!}
        buttonUrl={PAGE?.homeBannerBtn?.url!}
        gallery={dataBannerGallery!}
      />
      <Contact id="kontakt-gorny" title={PAGE?.homeContactTopTitle!} />
      <Box
        id="o-inwestycji"
        title={PAGE?.homeAboutTitle!}
        text={PAGE?.homeAboutText!}
        gallery={dataBoxGallery}
      />
      <OverlayedCarousel slides={dataOverlayedCarousel!} />
      <Pictograms
        title={PAGE?.homeForInvestorsTitle!}
        list={dataPictogramsList}
      />
      <MapSimple
        id="lokalizacja"
        imageMobile={
          PAGE?.homeLocationMapMobile?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        imageDesktop={
          PAGE?.homeLocationMapDesktop?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        buttonUrl={PAGE?.homeLocationMapLink!}
      />
      <About
        id="pekabex-development"
        cards={dataAboutCards}
        statsTitle={PAGE?.homeAbout2Text3!}
        // stats={dataAboutStats}
      />
      <Contact
        id="kontakt-dolny"
        title={PAGE?.homeContactBottomTitle!}
        styleType="primary"
      />
    </LayoutBasic>
  )
}

export default PageIndex

export const Head = () => {
  return <link rel="canonical" href="https://zatokawrzosowo.pl/" />
}

export const query = graphql`
  query PageIndex {
    wpPage(slug: { regex: "/" }) {
      PageHome {
        homeBannerTitle
        homeBannerSubtitle
        homeBannerSubtitlePresaleStart
        homeBannerBtn {
          title
          url
        }
        homeBannerGallery {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
        homeContactTopTitle
        homeAboutTitle
        homeAboutText
        homeAboutGallery {
          homeAboutGalleryImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
            }
          }
        }
        homeBenefitsList {
          homeBenefitsListTitle
          homeBenefitsListText
          homeBenefitsListPhoto {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
            }
          }
          homeBenefitsListPhotoMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
            }
          }
          homeBenefitsListPictograms {
            homeBenefitsListPictogramsTitle
            homeBenefitsListPictogramsIcon {
              localFile {
                publicURL
              }
            }
            homeBenefitsListPictogramsText
          }
        }
        homeAbout2Cards {
          homeAbout2CardsTitle
          homeAbout2CardsText
        }
        homeAbout2Text3
        homeAbout2Pekabex {
          homeAbout2PekabexText
          homeAbout2PekabexNumber
        }
        homeContactBottomTitle
        homeLocationMapLink
        homeLocationMapMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        homeLocationMapDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        homeForInvestorsTitle
        homeForInvestorsBenefits {
          homeForInvestorsBenefitsFirstRowText
          homeForInvestorsBenefitsFirstRowIcons {
            localFile {
              publicURL
            }
          }
        }
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
