import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  SidenavWrapper,
  SidenavHeader,
  SidenavBrand,
  SidenavToggler,
  SidenavTogglerIcon,
  SidenavContent,
  SidenavNavigation,
  SidenavItem,
  SidenavLink,
  SidenavFooter,
  SidenavContact,
} from 'components/organisms/Sidenav/Sidenav.style'

import generateKey from 'utils/generateKey'
import scrollToSection from 'utils/scrollToSection'
import { phoneClickTracker, contactClickTracker } from 'utils/analytics'

import { NAV_LINKS } from 'constants/navLinks'

import logo from 'assets/icons/logo.svg'
import iconBurger from 'assets/icons/burger.svg'

type Props = {
  visible: boolean
  closeSidenav: () => void
}

const Sidenav: React.FC<Props> = ({ visible, closeSidenav }) => {
  const query = useStaticQuery(graphql`
    query Sidenav {
      allWp {
        edges {
          node {
            themeSettings {
              AcfOptionsTheme {
                globalContactPhone
              }
            }
          }
        }
      }
    }
  `)

  const DATA = query?.allWp?.edges[0].node.themeSettings.AcfOptionsTheme

  if (!DATA) throw new Error("CMS data didn't load properly")

  return (
    <SidenavWrapper visible={visible}>
      <SidenavHeader>
        <SidenavBrand to="/">
          <img src={logo} alt="" />
        </SidenavBrand>
        <SidenavToggler onClick={closeSidenav}>
          <SidenavTogglerIcon src={iconBurger} size={28} />
        </SidenavToggler>
      </SidenavHeader>
      <SidenavContent>
        {NAV_LINKS && (
          <SidenavNavigation>
            {NAV_LINKS.map((item, index) => (
              <SidenavItem key={generateKey(index)}>
                <SidenavLink
                  onClick={() => {
                    scrollToSection(item.path, 'smooth')
                    closeSidenav()

                    if (item.path.includes('kontakt')) {
                      contactClickTracker()
                    }
                  }}
                  type="button"
                >
                  {item.title}
                </SidenavLink>
              </SidenavItem>
            ))}
          </SidenavNavigation>
        )}
      </SidenavContent>
      <SidenavFooter>
        <SidenavContact
          onClick={phoneClickTracker}
          href={`tel:${DATA.globalContactPhone}`}
        >
          {DATA.globalContactPhone}
        </SidenavContact>
      </SidenavFooter>
    </SidenavWrapper>
  )
}

export default Sidenav
