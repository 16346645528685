import styled from 'styled-components'
import { Link } from 'gatsby'

import { Image } from 'components/atoms/Image'

import media from 'styles/media'

export const FooterWrapper = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`

export const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  ${media.lg.min} {
    padding: 24px 0;
  }
`

export const FooterMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0 0 0;
  width: 100%;
  ${media.xl2.max} {
    padding: 32px 0 0 0;
  }
  ${media.lg.max} {
    padding: 0;
    flex-direction: column;
  }
`
export const FooterBrandWrapper = styled(Link)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.lg.min} {
    top: -28px;
  }

  ${media.xl2.min} {
    top: -44px;
  }
`

export const FooterBrand = styled(Image)`
  position: relative;
  height: 154px;
  width: 234px;
  ${media.lg.min} {
    width: 152px;
    height: 99px;
  }
  ${media.xl2.min} {
    height: 154px;
    width: 234px;
  }
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  ${media.lg.max} {
    margin: 32px 0 0 0;
  }
  ${media.lg.min} {
    flex-direction: row;
    height: 100px;
    padding: 0 0 0 64px;
  }

  ${media.xl2.min} {
    padding: 0 0 0 96px;
  }
`

export const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lg.max} {
    padding: 16px 0;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray5};
    text-align: center;
  }

  p,
  a {
    font-size: 20px;
    line-height: 27px;
    ${media.lg.min} {
      font-size: 16px;
      line-height: 23px;
    }
    ${media.xl2.min} {
      font-size: 20px;
      line-height: 27px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    transition: color 300ms ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.primary50};
    }
  }

  strong {
    font-weight: 700;
  }
`

export const FooterSub = styled.div`
  margin: 16px 0 0 0;
  padding: 0;
  ${media.lg.max} {
    margin: 32px 0 0 0;
  }
`

export const FooterCopyBrandWrapper = styled.a`
  position: relative;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.72;
  }
`

export const FooterCopyBrand = styled(Image)`
  height: 32px;
`
