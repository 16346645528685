import styled from 'styled-components'
import { Form } from 'formik'

import { ButtonPrimary } from 'components/atoms/Button'

type Props = {
  title?: string
  styleType?: 'primary'
}

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  & > * {
    margin-bottom: 20px;
  }
`

export const StyledTermsWrapper = styled.div``

export const ButtonSubmitWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
`

export const ButtonSubmit = styled.button<{
  styleType: Props['styleType']
}>`
  ${ButtonPrimary};

  ${({ styleType, theme }) =>
    styleType === 'primary' &&
    `
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.white};
    color: ${theme.colors.primary50};
    &:hover {
      background-color: transparent;
      border-color: ${theme.colors.white};
      color: ${theme.colors.white};

      svg {
        rect, path {
          fill: ${theme.colors.white};
        }
      }

    }
  `}

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

export const StyledThanks = styled.p<{
  styleType: Props['styleType']
}>`
  margin: 8px 0 0 0;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.primary50};
`
