import styled, { css } from 'styled-components'

import { InputProps, Props } from 'components/atoms/Form/Input.types'

export const InputItem = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  z-index: 9;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export const Label = styled.label<{
  invalid: boolean
  styleType: Props['styleType']
}>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 0 8px 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.black};
  white-space: nowrap;
  transition: opacity 0.2s, color 0.2s;
  cursor: pointer;
  span {
    color: ${({ styleType, theme }) =>
      styleType === 'primary' ? theme.colors.white : theme.colors.primary50};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.alert50};
    `}
`

export const StyledInput = styled.input<InputProps>`
  width: 100%;
  padding: ${({ textarea }) => (textarea ? '16px' : '0 16px')};
  height: ${({ textarea }) => (textarea ? '120px' : '44px')};
  font-size: 16px;
  line-height: 16px;
  background: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.primary50 : theme.colors.gray0};
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.black};
  border: ${({ styleType, theme }) =>
    styleType === 'primary'
      ? `1px solid rgba(255,255,255,.72)`
      : `1px solid ${theme.colors.black}`};
  border-radius: 0;
  transition: 0.3s;
  resize: none;
  outline: 0;
  &:focus {
    border-color: ${({ styleType, theme }) =>
      styleType === 'primary' ? theme.colors.white : theme.colors.black};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.alert50} !important;
      transition: none;
      color: ${({ theme }) => theme.colors.alert50};
      &:focus {
        color: ${({ theme }) => theme.colors.black};
        transition-duration: 0.2s;
        transition-property: color, opacity, border-color, background;
      }
    `}
`

export const ErrorMessage = styled.p`
  margin: 4px 0 0 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.alert50};
`
