import { Link } from 'gatsby'
import styled from 'styled-components'

import { Icon } from 'components/atoms/Icon'

import media from 'styles/media'

import iconPhone from 'assets/icons/phone-primary.svg'

export const SidenavWrapper = styled.section<{ visible: boolean }>`
  position: fixed;
  left: 0;
  top: ${({ visible }) => (visible ? 0 : '-100%')};
  margin: 0;
  padding: 8px 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0;
  z-index: 9999;
  transition: top 300ms ease-in-out;
  ${media.sm.min} {
    padding: 8px 32px;
  }
`

export const SidenavHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
`

export const SidenavBrand = styled(Link)`
  display: inline-block;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  & img {
    height: 88px;
    width: 133px;
  }
`

export const SidenavContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
  padding: 48px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray5};
`

export const SidenavNavigation = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SidenavItem = styled.li`
  margin: 0 0 32px 0;
  &:last-child {
    margin: 0;
  }
`

export const SidenavLink = styled.button`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  background-color: transparent;
  border: 0;
  transition: color 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const SidenavToggler = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
  padding: 0;
  border: 0;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primary50};
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 45px;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary100};
  }
`

export const SidenavTogglerIcon = styled(Icon)``

export const SidenavFooter = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray5};
`

export const SidenavContact = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 0 0 70px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  cursor: pointer;
  z-index: 9998;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 54px;
    height: 54px;
    border-radius: 54px;
    transform: translateY(-50%);
  }
  &:before {
    border: 1px solid ${({ theme }) => theme.colors.primary50};
    transition: background-color 300ms ease-in-out;
  }
  &:after {
    z-index: 9999;
    background-image: url(${iconPhone});
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    transition: filter 300ms ease-in-out;
  }
  &:hover {
    &:before {
      background-color: ${({ theme }) => theme.colors.primary50};
    }
    &:after {
      filter: brightness(0) invert(1);
    }
  }
`
