import { Link } from 'gatsby'
import styled from 'styled-components'

import { Icon } from 'components/atoms/Icon'

import media from 'styles/media'

import iconPhone from 'assets/icons/phone-primary.svg'

export const HeaderWrapper = styled.header<{ active: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 45px;
  z-index: 9998;
  transform: translate3d(0, ${({ active }) => (active ? '0' : '-100%')}, 0);
  transition: background-color 300ms, border-color 300ms ease-in-out,
    transform 300ms ease-in-out;
`

export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

export const HeaderBrand = styled(Link)`
  display: inline-block;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  & img {
    height: 88px;
    width: 133px;
  }
`

export const Navigation = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 0 0 auto;
  ${media.lg.max} {
    display: none;
  }
`

export const NavigationItem = styled.li`
  margin: 0 0 0 32px;
  &:first-child {
    margin: 0;
  }
`

export const NavigationLink = styled.button`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  background-color: transparent;
  border: 0;
  transition: color 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
export const HeaderContact = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  margin: 0 0 0 40px;
  padding: 0 0 0 64px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  cursor: pointer;
  z-index: 9998;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    transform: translateY(-50%);
  }
  &:before {
    border: 2px solid ${({ theme }) => theme.colors.primary50};
    transition: background-color 300ms ease-in-out;
  }
  &:after {
    z-index: 9999;
    background-image: url(${iconPhone});
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    transition: filter 300ms ease-in-out;
  }
  &:hover {
    &:before {
      background-color: ${({ theme }) => theme.colors.primary50};
    }
    &:after {
      filter: brightness(0) invert(1);
    }
  }
`

export const HeaderContactText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  ${media.lg.max} {
    display: none;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
`

export const HeaderToggler = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primary50};
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary100};
  }
  ${media.lg.min} {
    display: none;
  }
`

export const HeaderTogglerIcon = styled(Icon)``
