import React, { useState, useRef } from 'react'
import { Formik, Field, FormikHelpers } from 'formik'
import axios from 'axios'

import {
  StyledForm,
  StyledTermsWrapper,
  ButtonSubmitWrapper,
  ButtonSubmit,
  StyledThanks,
} from 'components/modules/ContactForm/ContactForm.style'
import FormInput from 'components/atoms/Form/Input'
import FormCheckbox from 'components/atoms/Form/Checkbox'

import { CONTACT_FORM_SCHEMA, CONTACT_FORM_INIT_VALUES } from 'constants/form'

import type { ContactFormValues } from 'types/form'

type Props = {
  id?: string
  styleType?: 'primary'
}

const ContactForm: React.FC<Props> = ({ id, styleType }) => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })

  const ref = useRef(null)

  return (
    <Formik
      innerRef={ref}
      initialValues={CONTACT_FORM_INIT_VALUES}
      validationSchema={CONTACT_FORM_SCHEMA}
      onSubmit={(
        values,
        { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
      ) => {
        if (formInfo.text && !formInfo.error) return
        ;(async () => {
          try {
            setSubmitting(true)

            setFormInfo({
              text: 'Wysyłanie wiadomości',
              error: false,
            })

            const { name, surname, phone, email, message, agree1, agree2 } =
              values

            const params = new FormData()

            params.set('name', `${name} ${surname}`)
            params.set('email', email)
            params.set('phone', phone!)
            params.set('message', message!)
            params.set('consent1', String(agree1))
            params.set('consent2', String(agree2))

            if (typeof window !== 'undefined') {
              params.set('url', String(window.location.href))
            }

            const response = await axios.post(
              'https://zatoka-wrzosowo.indigital.guru/mailer/mailer-main.php',
              params
            )

            if (response.data.status === 'success') {
              setSubmitting(false)

              if (typeof window !== 'undefined') {
                // @ts-ignore
                if (window.dataLayer) {
                  // @ts-ignore
                  window.dataLayer.push({
                    event: 'Formularz kontaktowy',
                    lead_id: response.data.leadId,
                    form_name:
                      id === 'kontakt-gorny'
                        ? 'Formularz kontaktowy górny'
                        : 'Formularz kontaktowy dolny',
                  })
                }

                // @ts-ignore
                if (window.fbq != null) {
                  // @ts-ignore
                  fbq('track', 'Lead', {
                    name,
                    surname,
                    email,
                    phone,
                    message,
                    agree1: String(agree1),
                    agree2: String(agree2),
                    lead_id: response.data.leadId,
                    form_name:
                      id === 'kontakt-gorny'
                        ? 'Formularz kontaktowy górny'
                        : 'Formularz kontaktowy dolny',
                  })
                }
              }

              setFormInfo({
                text: 'Wiadomość wysłana',
                error: false,
              })
            } else {
              setSubmitting(false)
              setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            }

            setTimeout(() => {
              setFormInfo({ text: '', error: false })
              resetForm()
            }, 5000)
          } catch (err) {
            setSubmitting(false)
            setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            setTimeout(() => {
              setFormInfo({ text: '', error: false })
            }, 5000)
          }
        })()
      }}
    >
      {() => (
        <StyledForm>
          <Field
            name="name"
            label="Imię"
            component={FormInput}
            styleType={styleType}
            required
          />

          <Field
            name="surname"
            label="Nazwisko"
            component={FormInput}
            styleType={styleType}
            // required
          />

          <Field
            name="email"
            label="Adres e-mail"
            component={FormInput}
            styleType={styleType}
            required
          />

          <Field
            name="phone"
            label="Numer telefonu"
            component={FormInput}
            styleType={styleType}
            required
          />

          <Field
            name="message"
            label="Wiadomość"
            component={FormInput}
            styleType={styleType}
            textarea
          />

          <StyledTermsWrapper>
            <Field
              type="checkbox"
              name="agree1"
              label="Wyrażam zgodę na otrzymywanie informacji handlowych drogą elektroniczną na adres e-mail lub numer telefonu wskazany powyżej."
              component={FormCheckbox}
              styleType={styleType}
              white
            />
            <Field
              type="checkbox"
              name="agree2"
              label={`Wyrażam zgodę na przetwarzanie moich danych osobowych przez spółkę Pekabex Development Sp. z o.o. w zakresie i w celach wskazanych w <a href="https://pekabex.pl/wp-content/files/Polityka_Prywatnosci_PI_XIV.pdf" target='blank'>polityce prywatności</a>.`}
              component={FormCheckbox}
              styleType={styleType}
              white
            />
          </StyledTermsWrapper>

          <ButtonSubmitWrapper>
            <ButtonSubmit
              disabled={!!(formInfo.text && !formInfo.error)}
              styleType={styleType}
              type="submit"
            >
              {formInfo.text ? formInfo.text : 'Zapytaj o ofertę'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.315"
                height="12.838"
                viewBox="0 0 14.315 12.838"
              >
                <g id="Group_586" data-name="Group 586">
                  <path
                    id="Path_25819"
                    data-name="Path 25819"
                    d="M14.166,6.035a.538.538,0,0,1-.2.873c-.6.273-1.21.54-1.815.81L.824,12.764A.544.544,0,0,1,.1,12.622a.533.533,0,0,1,.013-.614Q.771,10.952,1.432,9.9q1.05-1.678,2.1-3.356a.2.2,0,0,0,0-.248Q1.835,3.6.14.9A.606.606,0,0,1,.028.348.519.519,0,0,1,.764.056q1.125.5,2.248,1L13.865,5.879c.1.046.2.1.3.156"
                    transform="translate(0.001 0)"
                    fill={styleType === 'primary' ? '#782954' : '#fff'}
                  />
                </g>
              </svg>
            </ButtonSubmit>
          </ButtonSubmitWrapper>
          {formInfo.text !== 'Wysyłanie wiadomości' &&
            formInfo.text &&
            !formInfo.error && (
              <StyledThanks styleType={styleType}>
                Dziękujemy za wypełnienie formularza kontaktowego
              </StyledThanks>
            )}
        </StyledForm>
      )}
    </Formik>
  )
}

export default ContactForm
