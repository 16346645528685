import React, { useEffect, useRef, useState } from 'react'

import {
  OverlayedWrapper,
  OverlayedSlider,
  OverlayedSlide,
  OverlayedBackground,
  OverlayedInner,
  OverlayedContent,
  OverlayedTitle,
  OverlayedText,
  OverlayedPictograms,
  OverlayedPictogram,
  OverlayedPictogramIcon,
  OverlayedPictogramContent,
  OverlayedPictogramTitle,
  OverlayedPictogramText,
  OverlayedSliderControls,
  OverlayedSliderControlsInner,
  OverlayedSliderDots,
  OverlayedSliderDot,
  OverlayedSliderArrows,
  OverlayedSliderArrow,
} from 'components/organisms/Overlayed/OverlayedCarousel.style'
import Container from 'components/atoms/Container'
import { Icon } from 'components/atoms/Icon'

import useBreakpoint from 'hooks/useBreakpoint'

import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  id?: string
  slides: Array<{
    title: string
    text: string
    background: {
      mobile: IGatsbyImageData
      desktop: IGatsbyImageData
    }
    pictograms: Array<{ title: string; text: string; icon: string }> | undefined
  }>
}

const OverlayedCarousel: React.FC<Props> = ({ id, slides }) => {
  const slider = useRef<any>(null)
  const [sliderActive, setSliderActive] = useState(0)
  const [sliderSlidesNum, setSliderSlidesNum] = useState(0)

  useEffect(() => {
    setSliderSlidesNum(slider.current.innerSlider.state.slideCount)
  })

  const { lg } = useBreakpoint()

  return (
    <OverlayedWrapper id={id}>
      <OverlayedSlider
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        fade
        ref={slider}
        beforeChange={(current, next) => setSliderActive(next)}
      >
        {slides.map((slide, slideIndex) => (
          <OverlayedSlide key={generateKey(slideIndex)}>
            <OverlayedBackground
              src={lg ? slide.background.desktop : slide.background.mobile}
              alt=""
            />
            <Container>
              <OverlayedInner>
                <OverlayedContent>
                  {slide.title && (
                    <OverlayedTitle
                      dangerouslySetInnerHTML={{
                        __html: slide.title,
                      }}
                    />
                  )}
                  {slide.text && (
                    <OverlayedText
                      dangerouslySetInnerHTML={{
                        __html: slide.text,
                      }}
                    />
                  )}
                </OverlayedContent>
                {slide.pictograms && (
                  <OverlayedPictograms>
                    {slide.pictograms.map((pictogram, pictogramIndex) => (
                      <OverlayedPictogram key={generateKey(pictogramIndex)}>
                        <OverlayedPictogramIcon>
                          <Icon src={pictogram.icon} size={54} />
                        </OverlayedPictogramIcon>
                        <OverlayedPictogramContent>
                          <OverlayedPictogramTitle>
                            {pictogram.title}
                          </OverlayedPictogramTitle>
                          {pictogram.text && (
                            <OverlayedPictogramText>
                              {pictogram.text}
                            </OverlayedPictogramText>
                          )}
                        </OverlayedPictogramContent>
                      </OverlayedPictogram>
                    ))}
                  </OverlayedPictograms>
                )}
              </OverlayedInner>
            </Container>
          </OverlayedSlide>
        ))}
      </OverlayedSlider>
      {sliderSlidesNum > 1 && (
        <OverlayedSliderControls>
          <Container>
            <OverlayedSliderControlsInner>
              <OverlayedSliderDots>
                {[...Array(sliderSlidesNum)].map((e, i) => {
                  return (
                    <OverlayedSliderDot
                      active={i === sliderActive}
                      key={generateKey(e + i)}
                      onClick={() => slider.current.slickGoTo(i)}
                      type="button"
                    />
                  )
                })}
              </OverlayedSliderDots>
              <OverlayedSliderArrows>
                <OverlayedSliderArrow
                  onClick={() => slider.current.slickPrev()}
                >
                  Poprzedni
                </OverlayedSliderArrow>
                <OverlayedSliderArrow
                  modificator="right"
                  onClick={() => slider.current.slickNext()}
                >
                  Następny
                </OverlayedSliderArrow>
              </OverlayedSliderArrows>
            </OverlayedSliderControlsInner>
          </Container>
        </OverlayedSliderControls>
      )}
    </OverlayedWrapper>
  )
}

export default OverlayedCarousel
