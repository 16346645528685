import React from 'react'
import { FieldProps } from 'formik'

import {
  Label,
  InputItem,
  StyledInput,
  ErrorMessage,
} from 'components/atoms/Form/Input.style'

import generateKey from 'utils/generateKey'

import { Props } from 'components/atoms/Form/Input.types'

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
  textarea,
  required,
  disabled,
  readonly,
  styleType,
}) => {
  const labelText = (required ? `${label}<span>*</span>` : label) as string
  const labelError = (errors[name] && touched[name] && errors[name]) as string

  const invalid = !!(touched[name] && errors[name])
  const valid = !!(touched[name] && !errors[name])

  const uniqueID = generateKey(name)

  return (
    <InputItem disabled={!!disabled}>
      <Label
        htmlFor={uniqueID}
        invalid={invalid}
        dangerouslySetInnerHTML={{ __html: labelText }}
        styleType={styleType}
      />
      <StyledInput
        id={uniqueID}
        name={name}
        type="text"
        as={textarea ? 'textarea' : 'input'}
        textarea={!!textarea}
        placeholder=" "
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
        valid={valid}
        disabled={disabled}
        readOnly={!!readonly}
        styleType={styleType}
      />
      {invalid && (
        <ErrorMessage dangerouslySetInnerHTML={{ __html: labelError }} />
      )}
    </InputItem>
  )
}

export default FormInput
