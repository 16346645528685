import { css } from 'styled-components'

export const ButtonPrimary = css`
  position: relative;
  /* display: inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* padding: 28px 80px; */
  padding: 20px 40px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.primary50};
  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out,
    border-color 300ms ease-in-out;

  svg {
    margin-left: 10px;
  }

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary50};

    svg {
      rect,
      path {
        fill: ${({ theme }) => theme.colors.primary50};
      }
    }
  }
`
