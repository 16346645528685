import styled from 'styled-components'

import { LazyImage } from 'components/atoms/Image'
import { ButtonPrimary } from 'components/atoms/Button'

import media from 'styles/media'

export const Wrapper = styled.section`
  margin: 112px 0 140px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

export const MapImage = styled(LazyImage)`
  width: 100%;
`

export const Inner = styled.div`
  position: relative;
`

export const Control = styled.a`
  ${ButtonPrimary}
  margin: 12px 0 0 0;

  ${media.lg.min} {
    margin: 0;
    position: absolute;
    right: 0;
    bottom: -30px;
  }
`
