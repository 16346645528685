import React from 'react'

import {
  Wrapper,
  Inner,
  Title,
  Grid,
  GridItem,
  GridItemIcon,
  GridItemText,
} from 'components/organisms/Pictograms/Pictograms.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

type Props = {
  id?: string
  title?: string
  list: Array<{ icon: string; text: string }>
}

const Pictograms: React.FC<Props> = ({ id, title, list }) => {
  return (
    <Wrapper id={id}>
      <Container>
        <Inner>
          <Title>{title}</Title>
          <Grid>
            {list.map((pictogram, pictogramIndex) => (
              <GridItem key={generateKey(pictogramIndex)}>
                <GridItemIcon src={pictogram.icon} />
                <GridItemText>{pictogram.text}</GridItemText>
              </GridItem>
            ))}
          </Grid>
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default Pictograms
