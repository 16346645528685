import React from 'react'

import {
  BoxWrapper,
  BoxInner,
  BoxGallery,
  BoxGallerySlide,
  BoxImage,
  BoxContent,
  BoxTitle,
  BoxText,
} from 'components/organisms/Box/Box.style'
import Container from 'components/atoms/Container'

import { IGatsbyImageData } from 'gatsby-plugin-image'
import generateKey from 'utils/generateKey'

type Props = {
  id?: string
  title: string
  text: string
  gallery: Array<{ image: IGatsbyImageData }>
}

const Box: React.FC<Props> = ({ id, title, text, gallery }) => {
  return (
    <BoxWrapper id={id}>
      <Container>
        <BoxInner>
          {gallery && (
            <BoxGallery
              arrows={false}
              dots={false}
              slidesToShow={1}
              slidesToScroll={1}
              fade
            >
              {gallery.map((image, imageIndex) => (
                <BoxGallerySlide key={generateKey(imageIndex)}>
                  <BoxImage src={image.image} alt="" />
                </BoxGallerySlide>
              ))}
            </BoxGallery>
          )}
          <BoxContent>
            <BoxTitle
              dangerouslySetInnerHTML={{
                __html: title
                  .replace(/{/g, '<strong>')
                  .replace(/}/g, '</strong>'),
              }}
            />
            <BoxText
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </BoxContent>
        </BoxInner>
      </Container>
    </BoxWrapper>
  )
}

export default Box
