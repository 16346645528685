import styled, { css } from 'styled-components'
import media from 'styles/media'

const aboutTitle = css`
  position: relative;
  margin: 0 0 24px 0;
  padding: 0 0 24px 0;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 112px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  ${media.lg.max} {
    font-size: 28px;
    line-height: 36px;
  }
`

export const Wrapper = styled.section`
  margin: 112px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

export const Inner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 64px;
  grid-row-gap: 64px;

  ${media.lg.max} {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 32px;
  }
`

export const Card = styled.article`
  padding: 64px;
  border: 1px solid ${({ theme }) => theme.colors.primary50};

  ${media.lg.max} {
    padding: 32px;
  }
`

export const CardTitle = styled.h3`
  ${aboutTitle}
`

export const CardText = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};

  ${media.lg.max} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Stats = styled.div`
  margin: 48px 0 0 0;
  padding: 64px;
  border: 1px solid ${({ theme }) => theme.colors.primary50};

  ${media.lg.max} {
    margin: 32px 0 0 0;
    padding: 32px;
  }
`

export const StatsHeader = styled.div`
  margin: 0 0 48px 0;

  ${media.lg.max} {
    margin: 0 0 32px 0;
  }
`

export const StatsTitle = styled.h2`
  ${aboutTitle}
`

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 64px;

  ${media.sm.max} {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 48px;
  }
`

export const StatsItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StatsItemTitle = styled.h3`
  font-size: 84px;
  line-height: 84px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary50};

  ${media.lg.max} {
    font-size: 64px;
    line-height: 64px;
  }
`

export const StatsItemText = styled.p`
  margin: 4px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`
