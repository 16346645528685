import styled, { css } from 'styled-components'

import media from 'styles/media'

import backgroundDecorPrimary from 'assets/icons/decor-flower-primary.svg'
import backgroundDecorSecondary from 'assets/icons/decor-flower-secondary.svg'

type Props = {
  title?: string
  styleType?: 'primary'
}

export const ContactWrapper = styled.section<{ styleType: Props['styleType'] }>`
  position: relative;
  padding: 112px 0;
  background-color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.primary50 : theme.colors.gray0};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -230px;
    width: 600px;
    height: 100%;
    background-image: url(${backgroundDecorSecondary});
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;

    ${({ styleType }) =>
      styleType === 'primary' &&
      css`
        right: -300px;
        background-image: url(${backgroundDecorPrimary});
      `}

    ${media.lg.max} {
      background-size: 100% 60%;
    }
  }

  ${media.lg.max} {
    padding: 64px 0;
  }
`

export const ContactInner = styled.div`
  margin: 0 auto;
  max-width: 700px;
`

export const ContactHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 48px 0;
  ${media.lg.max} {
    margin: 0 0 32px 0;
  }
`

export const ContactTitle = styled.h2<{ styleType: Props['styleType'] }>`
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: ${({ styleType, theme }) =>
    styleType === 'primary' ? theme.colors.white : theme.colors.black};
  ${media.lg.max} {
    font-size: 32px;
    line-height: 40px;
  }
`
