const generateKey = (value: string | number) => {
  const key = value === undefined ? 0 : value

  const generatedKey = `${key}-${(Math.random() + 1).toString(36).substring(6)}`

  return [...generatedKey]
    .sort(() => Math.random() - 0.5)
    .join('')
    .replace(/\s/g, '')
}

export default generateKey
