import React from 'react'

import {
  Wrapper,
  Inner,
  Grid,
  Card,
  CardTitle,
  CardText,
  Stats,
  StatsHeader,
  StatsTitle,
  StatsGrid,
  StatsItem,
  StatsItemTitle,
  StatsItemText,
} from 'components/organisms/About/About.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

type Props = {
  id?: string
  cards: Array<{ title: string; text: string }>
  statsTitle: string
  stats?: Array<{
    title: string
    text: string
  }>
}

const About: React.FC<Props> = ({ id, cards, statsTitle, stats }) => {
  return (
    <Wrapper id={id}>
      <Container>
        <Inner>
          {cards && (
            <Grid>
              {cards.map((card, cardIndex) => (
                <Card key={generateKey(cardIndex)}>
                  <CardTitle>{card.title}</CardTitle>
                  <CardText dangerouslySetInnerHTML={{ __html: card.text }} />
                </Card>
              ))}
            </Grid>
          )}
          {stats && (
            <Stats>
              <StatsHeader>
                <StatsTitle>{statsTitle}</StatsTitle>
              </StatsHeader>
              <StatsGrid>
                {stats.map((stat, statIndex) => (
                  <StatsItem key={generateKey(statIndex)}>
                    <StatsItemTitle>{stat.title}</StatsItemTitle>
                    <StatsItemText
                      dangerouslySetInnerHTML={{ __html: stat.text }}
                    />
                  </StatsItem>
                ))}
              </StatsGrid>
            </Stats>
          )}
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default About
