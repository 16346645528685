import React, { useState } from 'react'
import { FieldProps } from 'formik'

import {
  Label,
  LabelText,
  LabelBtn,
  Checkbox,
  Checkmark,
} from 'components/atoms/Form/Checkbox.style'

import { Props } from 'components/atoms/Form/Checkbox.types'
import generateKey from 'utils/generateKey'

const FormCheckbox: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
  styleType,
}) => {
  const [expanded, setExpaned] = useState(false)

  const invalid = !!(touched[name] && errors[name])
  const uniqueID = generateKey(name)

  return (
    <Label htmlFor={uniqueID}>
      <LabelText
        expanded={expanded}
        dangerouslySetInnerHTML={{ __html: label }}
        styleType={styleType}
      />
      <LabelBtn
        type="button"
        onClick={() => {
          setExpaned(!expanded)
        }}
        styleType={styleType}
      >
        {expanded ? 'Mniej' : 'Więcej'}
      </LabelBtn>
      <Checkbox
        id={uniqueID}
        name={name}
        type="checkbox"
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
        styleType={styleType}
      />
      <Checkmark error={invalid} styleType={styleType} />
    </Label>
  )
}

export default FormCheckbox
