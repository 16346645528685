import styled from 'styled-components'
import { Link } from 'gatsby'

import { Icon } from 'components/atoms/Icon'

import media from 'styles/media'

import iconPhone from 'assets/icons/phone-primary.svg'

export const HeaderWrapper = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 9997;
`

export const HeaderBrand = styled(Link)`
  position: relative;
  display: inline-block;
  transition: opacity 300ms ease-in-out;
  z-index: 9997;
  &:hover {
    opacity: 0.8;
  }
  & img {
    width: 190px;
    height: 125px;
  }

  ${media.lg.max} {
    & img {
      height: 88px;
      width: 133px;
    }
  }
`

export const HeaderBar = styled.div`
  position: relative;

  ${media.lg.max} {
    display: none;
  }
`

export const HeaderBarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 86px;
`

export const HeaderContactWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${media.lg.min} {
    padding: 0 0 0 24px;
    height: 100%;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 50vw;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`

export const HeaderContact = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 0 0 60px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 9998;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 44px;
    height: 44px;
    border: 0;
    border-radius: 44px;
    transform: translateY(-50%);
  }
  &:before {
    background-color: ${({ theme }) => theme.colors.white};
    transition: background-color 300ms ease-in-out;
  }
  &:after {
    z-index: 9999;
    background-image: url(${iconPhone});
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    transition: filter 300ms ease-in-out;
  }
  &:hover {
    &:before {
      background-color: ${({ theme }) => theme.colors.primary100};
    }
    &:after {
      filter: brightness(0) invert(1);
    }
  }

  ${media.lg.max} {
    height: 48px;
    width: 48px;
    padding: 0;
    margin: 0 16px 0 0;
    &:before,
    &:after {
      width: 48px;
      height: 48px;
    }
    &:before {
      background-color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primary50};
    }
  }
`

export const HeaderContent = styled.div`
  position: relative;
  margin: -86px 0 0 0;
  padding: 24px 0 0 0;
  ${media.lg.max} {
    margin: 0;
    padding: 8px 0;
  }
`

export const HeaderContentInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  justify-content: flex-start;
  ${media.lg.min} {
    min-width: 869px;
    align-items: flex-end;
  }
`

export const HeaderContentMobile = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  ${media.lg.min} {
    display: none;
  }
`

export const Navigation = styled.ul`
  position: relative;
  bottom: 24px;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 0 0 40px;
  ${media.lg.max} {
    display: none;
  }
`

export const NavigationItem = styled.li`
  margin: 0 0 0 28px;
  &:first-child {
    margin: 0;
  }
`

export const NavigationLink = styled.button`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  background-color: transparent;
  border: 0;
  transition: color 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const HeaderToggler = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primary50};
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary100};
  }
`

export const HeaderTogglerIcon = styled(Icon)``
