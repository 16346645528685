import styled from 'styled-components'

import media from 'styles/media'

import imageBg1 from 'assets/images/pictograms-bg-1.svg'
import imageBg2 from 'assets/images/pictograms-bg-2.svg'

export const Wrapper = styled.div`
  margin: 124px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

export const Inner = styled.div`
  position: relative;
  padding: 64px;
  border: 1px solid ${({ theme }) => theme.colors.primary50};
  &::before,
  &:after {
    content: '';

    position: absolute;
    width: 400px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:before {
    top: 40px;
    left: 0;
    background-image: url(${imageBg1});
    background-position: top left;
  }

  &:after {
    top: unset;
    bottom: 0;
    right: 0;
    background-image: url(${imageBg2});
    background-position: bottom right;
  }

  ${media.lg.max} {
    padding: 48px;
  }
`

export const Title = styled.h3`
  position: relative;
  margin: 0 0 24px 0;
  padding: 0 0 24px 0;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
  z-index: 9;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 112px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  ${media.lg.max} {
    margin: 0 0 48px 0;
    font-size: 28px;
    line-height: 36px;
  }
`

export const Grid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 -80px 0;
  z-index: 9;

  ${media.lg.max} {
    margin: 0 0 -48px 0;
  }
`

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin: 0 0 80px 0;
  padding: 0 32px;
  &:nth-child(-n + 3) {
    width: calc(100% / 3);
  }

  ${media.xl.max} {
    width: 50%;
    &:nth-child(-n + 3) {
      width: 50%;
    }
  }

  ${media.lg.max} {
    margin: 0 0 48px;

    width: 100%;
    &:nth-child(-n + 3) {
      width: 100%;
    }
  }
`

export const GridItemIcon = styled.img`
  height: 64px;
  width: 64px;
  object-fit: contain;

  ${media.lg.max} {
    height: 48px;
    width: 48px;
  }
`

export const GridItemText = styled.p`
  margin: 12px 0 0 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;

  ${media.lg.max} {
    font-size: 16px;
    line-height: 24px;
  }
`
